import type { BlockTypeProps } from '@backmarket/http-api/src/api-specs-content'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import { transformHref } from './transformHref'

/**
 *
 * @param props - Content service block props
 * @param currentUrl - Current location URL
 * @returns Content service block props with relative links if they match the current location domain.
 *
 * @example
  buildPropsWithCorrectLinks({
    props: {
      primary: {
        link: {
          href: 'https://preprod.backmarket.com/en-us/e/good-deals',
        },
        label: 'Good deals',
      },
      secondary: {
        link: {
          href: 'https://www.trustpilot.com',
        },
        label: 'Trustpilot',
      },
    },
    currentUrl: new URL('https://www.backmarket.com/en-us'),
  })
  // returns:
  {
    primary: {
      link: {
        href: '/en-us/e/good-deals',
      },
      label: 'Good deals',
    },
    secondary: {
      link: {
        href: 'https://www.trustpilot.com',
        target: '_blank'
        rel: 'nofollow'
      },
      label: 'Trustpilot',
    },
  }
 */
export function buildPropsWithCorrectLinks({
  props,
  currentUrl,
}: {
  props: BlockTypeProps
  currentUrl: URL
}): BlockTypeProps {
  const result: Record<string, unknown> = {}

  for (const [name, value] of Object.entries(props)) {
    if (name === 'href') {
      const { href, target, rel } = transformHref({ href: value, currentUrl })
      result.href = href
      if (target) result.target = target
      if (rel) result.rel = rel
    } else if (isEmpty(value) || typeof value !== 'object') {
      result[name] = value
    } else if (Array.isArray(value)) {
      result[name] = value.map((nestedProps) =>
        buildPropsWithCorrectLinks({
          props: nestedProps,
          currentUrl,
        }),
      )
    } else {
      result[name] = buildPropsWithCorrectLinks({ props: value, currentUrl })
    }
  }

  return result
}
