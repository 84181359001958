import { useRouteParams } from '#imports'

import { getPriceGuide } from '@backmarket/http-api/src/api-specs-content'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'

export async function useGetPriceGuide() {
  const { productModelSlug } = useRouteParams()

  return useHttpFetch(getPriceGuide, {
    pathParams: { productModelSlug },
  })
}
