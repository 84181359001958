import type { DefaultHttpRequestBody, HttpError } from '@backmarket/http-api'
import type { BasePageContent } from '@backmarket/http-api/src/api-specs-content'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'

import { useFormatContentResponse } from './useFormatContentResponse'

export function useFetchFormattedPageContent<
  T extends BasePageContent,
  ErrorT = HttpError,
  BodyT = DefaultHttpRequestBody,
  DataT = BasePageContent,
>(
  endpoint: Parameters<typeof useHttpFetch<T, ErrorT, BodyT, DataT>>[0],
  options: Parameters<typeof useHttpFetch<T, ErrorT, BodyT, DataT>>[1],
) {
  const formatContentResponse = useFormatContentResponse()

  const { transform, ...restOptions } = options || {}

  const transformFn: (data: T) => DataT = transform
    ? (data: T) => transform(formatContentResponse(data))
    : (formatContentResponse as (data: T) => DataT)

  return useHttpFetch(endpoint, {
    ...restOptions,
    transform: transformFn,
  })
}
