<template>
  <RevBreadcrumb
    v-if="priceGuide"
    :ariaLabel="i18n(translations.breadcrumb)"
    :breads="breads"
    class="mb-24"
  />

  <RevContainer>
    <div class="flex justify-center">
      <div class="max-w-[736px] grow">
        <h1 class="heading-1">
          {{ i18n(translations.title, { productModel }) }}
        </h1>
        <span class="text-static-default-low body-2">
          {{ i18n(translations.subtitle, { productModel }) }}
        </span>
      </div>
    </div>
    <RevCard class="my-56 px-40 py-24">
      <RevContentBlock
        :button-label="i18n(translations.whyRefurbishedReadMore)"
        button-variant="secondary"
        :hasRoundedImage="true"
        :image-props="{
          alt: '',
          height: 252,
          width: 448,
          src: '/img/price-guide/refurbished.svg',
        }"
        :title="i18n(translations.whyRefurbishedTitle)"
        title-tag="h2"
        title-variant="title1"
      >
        <p class="body-1">
          {{ i18n(translations.whyRefurbishedDescription) }}
        </p>
      </RevContentBlock>
    </RevCard>
  </RevContainer>
</template>

<script setup lang="ts">
import { useHead } from '#imports'
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevBreadcrumb } from '@ds/components/Breadcrumb'
import { RevCard } from '@ds/components/Card'
import { RevContainer } from '@ds/components/Container'
import { RevContentBlock } from '@ds/components/ContentBlock'

import { useRouteLocationWithLocale } from '../../../composables/useRouteLocationWithLocale'
import { HOME } from '../../home/route-names'

import translations from './PriceGuideProductModel.translations'
import { useGetPriceGuide } from './composables/useGetPriceGuide'
import { useHandleError } from './composables/useHandleError'

const i18n = useI18n()
const resolveLocalizedRoute = useRouteLocationWithLocale()

const { data: priceGuide, error } = await useGetPriceGuide()

useHandleError(error)

const productModel = computed(() => priceGuide.value?.productModel)
const breads = computed(() => [
  {
    link: resolveLocalizedRoute({ name: HOME }),
    title: i18n(translations.home),
  },
  {
    link: {},
    title: i18n(translations.title, {
      productModel: productModel.value,
    }),
  },
])

useHead({
  title: i18n(translations.title, {
    productModel: productModel.value,
  }),
})
</script>
