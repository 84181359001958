import { useRequestURL } from '#imports'

import type { BasePageContentAPI } from '@backmarket/http-api/src/api-specs-content'
import { insertIf } from '@backmarket/utils/collection/insertIf'

import { buildPropsWithCorrectLinks } from '../helpers/link/buildPropsWithCorrectLinks'

// TODO COM-1345 remove pageName and pageType
export function useFormatContentResponse() {
  const currentUrl = useRequestURL()

  function formatContentResponse<P extends BasePageContentAPI>(response: P) {
    const pageName = 'name' in response ? response.name : response.pageName
    const pageType = 'type' in response ? response.type : response.pageType
    const parentPageInfosPageName =
      response.parentPageInfos &&
      ('name' in response.parentPageInfos
        ? response.parentPageInfos.name
        : response.parentPageInfos.pageName)

    return {
      ...response,
      pageName,
      pageType,
      ...insertIf(!!parentPageInfosPageName, {
        parentPageInfos: {
          ...response.parentPageInfos,
          pageName: parentPageInfosPageName,
        },
      }),
      blocks: response.blocks.map((block) => ({
        ...block,
        props: buildPropsWithCorrectLinks({
          props: block.props,
          currentUrl,
        }),
      })),
    }
  }

  return formatContentResponse
}
