import type { BlockType } from '@backmarket/http-api/src/api-specs-content'

export const FULL_WIDTH_BLOCKS: BlockType[] = [
  'Carousel',
  'Faq',
  'SectionBanner',
] as const

export const PADDED_FULL_WIDTH_BLOCKS: BlockType[] = ['RichText'] as const

export const NARROW_BLOCKS: BlockType[] = [
  'EmbeddedYoutubeVideo',
  'Buttons',
  'Media',
  'Categories',
] as const

function isNarrow(type: BlockType) {
  return NARROW_BLOCKS.includes(type)
}

function isFullWidthWithPadding(type: BlockType) {
  return PADDED_FULL_WIDTH_BLOCKS.includes(type)
}
export function isFullWidth(type: BlockType) {
  return FULL_WIDTH_BLOCKS.includes(type)
}

function isLarge(type: BlockType) {
  return !isNarrow(type) && !isFullWidth(type) && !isFullWidthWithPadding(type)
}

export function getDimensionProperties(type: BlockType) {
  return {
    hasPadding: !isFullWidth(type),
    isNarrow: isNarrow(type),
    isLarge: isLarge(type),
  }
}
