export default {
  breadcrumb: {
    id: 'breadcrumb_aria_label',
    defaultMessage: 'Breadcrumb',
  },

  title: {
    id: 'price_guide_product_model_title',
    defaultMessage: '{productModel} price guide',
  },

  subtitle: {
    id: 'price_guide_product_model_subtitle',
    defaultMessage:
      "Options, options, options - let's help you find the right {productModel}.",
  },

  whyRefurbishedReadMore: {
    id: 'price_guide_product_model_why_refurbished_read_more',
    defaultMessage: 'Read more',
  },

  whyRefurbishedTitle: {
    id: 'price_guide_product_model_why_refurbished_title',
    defaultMessage: 'Why shop refurbished?',
  },

  whyRefurbishedDescription: {
    id: 'price_guide_product_model_why_refurbished_description',
    defaultMessage:
      "High tech at low cost - for your wallet, and the planet. How? It's tech reborn: old tech restored to like new quality by expert refurbishers.",
  },

  home: {
    id: 'price_guide_product_model_breadcrumb_home',
    defaultMessage: 'Home',
  },
} as const
