import {
  getPageCategoryContent,
  getPageCategoryContentLegacy,
  getPageContent,
  getPageContentLegacy,
} from '@backmarket/http-api/src/api-specs-content'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import { useFetchFormattedPageContent } from './useFetchFormattedPageContent'
import { usePageParamsFromRoute } from './usePageParamsFromRoute'

// TODO COM-1345 Remove the param and use only the content service endpoints
export function useFetchContent(countriesWithContentServiceEndpoint = '') {
  const { market } = useMarketplace()

  const { pageContentEndpoint, pageCategoryEndpoint } =
    countriesWithContentServiceEndpoint.includes(market.countryCode)
      ? {
          pageContentEndpoint: getPageContent,
          pageCategoryEndpoint: getPageCategoryContent,
        }
      : {
          pageContentEndpoint: getPageContentLegacy,
          pageCategoryEndpoint: getPageCategoryContentLegacy,
        }

  const pathParams = usePageParamsFromRoute()

  const endpoint = isEmpty(pathParams.pageCategory)
    ? pageContentEndpoint
    : pageCategoryEndpoint

  return useFetchFormattedPageContent(endpoint, {
    pathParams,
  })
}
